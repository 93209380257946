import React from "react"
import homeStyles from "../components/index.module.scss"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"


export default function Specialists() {
  const data = useStaticQuery(graphql`
    query {
      allWordpressWpUsers {
        edges {
          node {
            slug
            name
            description
            mpp_avatar {
              full {
                source_url
                title
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className={homeStyles.specialistSection}>
      <h2 className={homeStyles.specialistTitle}>
        ¿Tienes preguntas acerca de algún tema?
      </h2>
      <h3 className={homeStyles.specialistTitle}>
        Nuestros especialistas te pueden ayudar
      </h3>
      <div className={homeStyles.specialistMapping}>
        {data.allWordpressWpUsers.edges.map(edge => {
          return (
            <div className={homeStyles.specialistContainer}>
              <img
                className={homeStyles.authorImage}
                src={edge.node.mpp_avatar.full.source_url}
                alt={edge.node.mpp_avatar.full.title}
              />
              <h4 className={homeStyles.authorName}>{edge.node.name}</h4>
              <p className={homeStyles.authorDescription}>
                {edge.node.description.length > 150
                  ? edge.node.description.slice(0, 150)
                  : edge.node.description}
              </p>
              <Link
                to={`/specialists/${edge.node.slug}`}
                title={`Página de ${edge.node.name}`}
              >
                <button className={homeStyles.authorButton}>
                  Más Información
                </button>
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}
